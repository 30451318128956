/*
    Remove the default padding of events in the calendar
*/
.rbc-event,
.rbc-day-slot .rbc-background-event {
  padding: 0 !important;
}

/*
    Remove the default outline from the event when it is focused
*/
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 0 !important;
}

/*
    Remove the default styling from week view events
*/
.rbc-event-label {
  display: none !important;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 0 !important;
}
