.eventsRow {
  width: 100%;
  display: flex;
  gap: 4px;
}

.eventsRowColumn {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.eventsRowItems {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.eventsRowItem {
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #ff714d;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
