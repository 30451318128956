.item {
  padding: 15px 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;
}

.categoryColor {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin: 0 4px;
}

.name {
  margin: 0;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}
